body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-modal, .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  top: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
 }
 .ant-modal-body {
  height: calc(100vh - 110px);
 }
